
import {useEffect} from 'react';
import HeroVideo from 'src/components/HeroVideo';
import CompteurContainer from 'src/components/CompteurContainer';
import Partenaires from 'src/components/Partenaires';
import Scrollbar from 'smooth-scrollbar';
import MembersContainer from 'src/components/MembersContainer';
import ContactUs from 'src/components/ContactUs';
import Expertise from 'src/components/Expertise';
import {loadPartnersListSimple} from 'src/features/partners';
import styles from './HomePage.module.css';
import VideoIntro from '../../components/VideoIntro';
import {Helmet} from 'react-helmet';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
// import 'aos/dist/aos.css';

const HomePage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadPartnersListSimple());
  }, [dispatch]);

  const {deviceType, videoPlayed} = useAppSelector((s) => s.main);

  const allTargets = Scrollbar.getAll();
  const target = allTargets[0];

  useEffect(() => {
    if (window.scrollY < 1 && target) {
      target.scrollTo(0, 100, 600);
    }
  }, [target]);

  return (
    <div id="topAnchor" className={[styles.homeContainer, deviceType === 'mobile' && styles.swiper].join(' ')}>
      <Helmet>
        <title>ALTTAB Productions – ÉVÉNEMENTIEL – AUDIOVISUEL – GAMING – AGENCE – ESPORT – PARIS</title>
        <meta
          name="description"
          content="Agence de conception et réalisation évènementiel, expert en média gaming. Basée à Paris, nous sommes à votre service pour vous accompagner dans vos projets."
        />
      </Helmet>
      { !videoPlayed && <VideoIntro /> }
      <HeroVideo />
      <CompteurContainer />
      <Expertise />
      <div className={styles.backgroundPartenairesMembers}>
        <Partenaires />
        <MembersContainer/>
        <ContactUs />
      </div>

    </div>
  );
};

export default HomePage;
