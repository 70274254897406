import {useRef} from 'react';
import ReactPlayer from 'react-player';
import {useDispatch} from 'react-redux';
import {videoWasPlayed} from 'src/features/main';
import styles from './VideoIntro.module.css';
import video from '../../assets/videos/alt-tab-logo-animation.mp4';

const VideoIntro = () => {
  const dispatch = useDispatch();

  const vid = useRef(null);

  const handleTimeUpdate = () => {
    if (vid !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // setVideoCurrent(vid.current.getCurrentTime());
      // console.log(vid.current.getCurrentTime())
      if (vid.current.getCurrentTime() > 3.9) {
        dispatch(videoWasPlayed());
      }
    }
  };
  return (
    <div className={[styles.videoIntro].join(' ')}>
      <ReactPlayer
        url={video}
        muted
        playing
        ref={vid}
        className={styles.videoElement}
        id="videoIntroID"
        onTimeUpdate={() => {
          handleTimeUpdate();
          // Quand la vidéo est finie, faire un dispatch(videoWasPlayed())
        }}
      />
    </div>
  );
};

export default VideoIntro;
