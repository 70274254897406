/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {loadEventByTitle} from 'src/features/events';
import YouTube from 'react-youtube';
import altTabLogo from '../../assets/logo/logo_horizontal_simple.png';
import styles from './ProjectDetails.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface ProjectDetailsParams {
  titleparam: string;
}
const ProjectDetails = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const {title} = useParams();
  const {eventDetails} = useAppSelector((s) => s.events);
  const {deviceType} = useAppSelector((s) => s.main);
  const [autoOpts, setAutoOpts] = useState({height: '400', width: '400'});
  useEffect(() => {
    if (title) dispatch(loadEventByTitle(title));
  }, [dispatch, title]);

  let startDate;
  if (eventDetails) {
    startDate = new Date(eventDetails.start_date);
  }

  useEffect(() => {
    const mediaImg = document.querySelector('#mediaImg') as HTMLElement;
    if (mediaImg) {
      const mediaImgHeight = mediaImg.offsetHeight;
      const mediaImgWidth = mediaImg.offsetWidth;
      setAutoOpts({height: `${mediaImgHeight}`, width: `${mediaImgWidth}`});
    }
  }, []);

  const opts = {
    height: '100%',
    width: '100%',

  };
  const onReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.stopVideo();
  };

  const getChannelId = (url: string) => {
    // eslint-disable-next-line max-len
    const regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    const match = url.match(regExp);
    return (match && match[1].length === 11) ? match[1] : '';
  };
  return (
    <div className={styles.projectContainer}>
      {!!eventDetails && (
      <div>
        {deviceType === 'desktop' && <div className={styles.pageTitle}>{title?.toUpperCase()}</div> }
        <div className={styles.header}>
          <div className={styles.headerTitle}>
          {title && <span className={styles.title}>{title}</span>}
          <div className={styles.subtitleClientsContainer}>
            {eventDetails?.clients.map((client) => <p className={styles.subtitleClient} key={client._id}>{client.title}</p>)}
          </div>
          </div>
          <div className={styles.location}>Studios Alt Tab Productions</div>
          <div className={styles.date}>
            {`${startDate?.getMonth()} / ${startDate?.getFullYear()}`}
            <p style={{margin: 0}}>|</p>
          </div>
        </div>
        <img src={eventDetails?.banner} className={styles.banner} />
        <div className={styles.eventDescriptionContainer}>
          <div className={styles.eventDescription}>
            <span className={styles.spanDescription}>Description: </span>
            {eventDetails?.description}
          </div>
          <div className={styles.eventRessources}>
            <span className={styles.spanRessources}>Ressources mobilisées ({`${eventDetails?.ressources.length}`}) : </span>
            <div className={styles.ressourcesContainer}>
            {eventDetails?.ressources.map((r, i) => (
                <div className={styles.ressourceItemContainer} key={r._id}>
                  <p className={styles.ressourceItem}>{r.description}</p>
                </div>
              // if (i === eventDetails?.ressources.length - 1) return <div>`${r.description}.`</div>
              // return <div>`${r.description} - `</div>
            ))}
              </div>
          </div>
        </div>
        <div className={styles.mediaContainer}>
            {eventDetails.link === 'null' ? (
              <>
                <div className={styles.projectPhotoContainer}>
                  <img src={eventDetails?.photos[0]} alt="" className={styles.photo} />
                </div>
                <YouTube className={styles.mediaVideo} videoId={getChannelId(eventDetails.link)} opts={opts} onReady={onReady} />
                <div className={styles.projectPhotoContainer}>
                  <img src={eventDetails?.photos[1]} alt="" className={styles.photo} />
                </div>
              </>
            ) : (
              <>
              {eventDetails?.photos.map((photo: string) => (
                // 3 max
                <div className={styles.projectPhotoContainer} key={photo}>
                  <img src={photo} alt="" className={styles.photo} />
                </div>
              ))}
              </>
            )}
          {/* {eventDetails?.photos.map((photo: string) => (
            // 3 max
            <div className={`col-lg-4 ${styles.clientPhotoContainer}`}>
              <img src={photo} alt="" className={styles.photo} />
            </div>
          ))} */}
        </div>
        <div className={styles.clientDetailsContainer} >
          {eventDetails?.clients.map((client) => (
            <div className={styles.clientDetails} key={client._id}>
              <div className={styles.clientPhotoContainer}>
                <img id="mediaImg" src={client.imageUrl} alt="" />
              </div>
              {/* <div className={styles.separatorContainer}>
                <span />
              </div> */}
            </div>
          ))}
        </div>

        <div className={styles.allProjectsContainer}>
          <div className={styles.allProjects} onClick={() => history('/realisations')}>
            <p className={styles.voirLesProjets}>VOIR TOUS LES&nbsp;{' '}
            <span className={styles.allProjectsSpan}>PROJETS</span>
            </p>
            <img
              src={altTabLogo}
              className={styles.allProjectImage}
            />
          </div>
          <img src="LOGOalttab" alt="" />
        </div>
      </div>
      )}

    </div>
  );
};

export default ProjectDetails;
